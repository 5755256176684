<template>
  <div>
    <v-row>
      <v-col cols="auto" align-self="center">
        <v-icon class="mr-2" x-large>mdi-account</v-icon>
      </v-col>
      <v-col align-self="center">
        <h1>Mein Account</h1>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row justify="center">
      <v-col cols="12">
        <h3>Account-Daten</h3>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          filled
          label="Anzeigename"
          v-model="useredit.displayName"
          v-on:change="update_display_name()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="user.provider == 'facebook.com'">
        <v-icon large class="mr-2">mdi-facebook</v-icon>
        <span class="font-weight-bold">Eingeloggt mit Facebook-Account</span>
      </v-col>
      <v-col cols="12" md="6" v-if="user.provider == 'google.com'">
        <v-icon large class="mr-2">mdi-google</v-icon>
        <span class="font-weight-bold">Eingeloggt mit Google-Account</span>
      </v-col>
      <v-col cols="12" md="6" v-if="user.provider == 'twitter.com'">
        <v-icon large class="mr-2">mdi-twitter</v-icon>
        <span class="font-weight-bold">Eingeloggt mit Twitter-Account</span>
      </v-col>
      <v-col cols="12" md="6" v-if="user.provider == 'password'">
        <v-form ref="emailform" v-model="emailvalid" lazy-validation>
          <v-text-field
            filled
            label="E-Mail Adresse"
            v-model="useredit.email"
            :rules="[rules.required, rules.email]"
            v-on:change="update_user_email()"
          ></v-text-field>
        </v-form>
      </v-col>
      <v-col cols="12" v-if="user.provider == 'password'">
        <v-divider></v-divider>
      </v-col>
      <v-expand-transition>
        <v-col cols="12" v-if="user.provider == 'password' && change_pw">
          <v-form ref="pwform" v-model="pwvalid" lazy-validation>
            <v-row justify="center">
              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  type="password"
                  label="Neues Passwort"
                  v-model="useredit.passwort"
                  :rules="[rules.required, rules.password]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  filled
                  type="password"
                  label="Neues Passwort bestätigen"
                  v-model="useredit.passwortconfirm"
                  :rules="[rules.required, rules.passwordconfirm]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-expand-transition>
      <v-col cols="12" md="6" v-if="user.provider == 'password'">
        <v-btn
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          :outlined="change_pw"
          large
          block
          @click="change_pw = !change_pw"
        >
          {{ change_pw ? 'Abbrechen' : 'Passwort ändern' }}
        </v-btn>
      </v-col>
      <v-expand-x-transition>
        <v-col cols="12" md="6" v-if="user.provider == 'password' && change_pw">
          <v-btn
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            large
            block
            @click="update_user_password()"
          >
            Speichern
          </v-btn>
        </v-col>
      </v-expand-x-transition>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-form ref="zsform" v-model="zsvalid" lazy-validation>
      <v-row justify="center">
        <v-col cols="12">
          <h3>Kontakt-Daten</h3>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            label="Vorname"
            v-model="useredit.zuschauerdaten.vorname"
            :rules="[rules.required, rules.name]"
            @change="update_user_zuschauerdaten()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            label="Nachname"
            :rules="[rules.required, rules.name]"
            v-model="useredit.zuschauerdaten.name"
            @change="update_user_zuschauerdaten()"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            label="Straße + Nr"
            :rules="[rules.required, rules.street, rules.streetnr]"
            v-model="useredit.zuschauerdaten.adresse"
            @change="update_user_zuschauerdaten()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            filled
            label="Postleitzahl"
            :rules="[rules.required, rules.plz]"
            v-model="useredit.zuschauerdaten.plz"
            @change="update_user_zuschauerdaten()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="8">
          <v-text-field
            filled
            label="Ort"
            :rules="[rules.required, rules.name]"
            v-model="useredit.zuschauerdaten.ort"
            @change="update_user_zuschauerdaten()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            label="Telefon"
            :rules="[rules.required, rules.telefon]"
            v-model="useredit.zuschauerdaten.telefon"
            @change="update_user_zuschauerdaten()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
        <v-col cols="12">
          <v-row justify="center" class="mt-4">
            <v-col cols="12">
              <v-card
                :dark="!template.light"
                :light="template.light"
                class="rounded-xl pa-4"
              >
                <v-row justify="center">
                  <v-col cols="12" md="6" lg="5">
                    <v-btn
                      :color="template.colors.primary"
                      :style="`color: ${template.colors.inline_primary_text};`"
                      class="my-3"
                      large
                      block
                      :loading="deleting"
                      @click="
                        deleteconfirm ? deleteUser() : (deleteconfirm = true)
                      "
                    >
                      <div v-if="!deleteconfirm">
                        <v-icon class="mr-2">mdi-account-remove</v-icon>
                        BENUTZER LÖSCHEN
                      </div>
                      <div v-if="deleteconfirm">
                        <v-icon class="mr-2">mdi-account-remove</v-icon>
                        LÖSCHEN BESTÄTIGEN
                      </div>
                    </v-btn>
                    <v-btn
                      :color="template.colors.primary"
                      :style="`color: ${template.colors.inline_primary_text};`"
                      class="my-3"
                      large
                      block
                      outlined
                      v-if="deleteconfirm"
                      @click="deleteconfirm = false"
                    >
                      Abbrechen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="reauth"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :persistent="$vuetify.breakpoint.lgAndUp"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
      dark
      style="padding-top: env(safe-area-inset-top);"
    >
      <v-card style="width: 100vw; overflow-x: hidden;">
        <v-toolbar
          fixed
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          style="
            padding-top: env(safe-area-inset-top);
            height: 80px + env(safe-area-inset-top);
          "
        >
          <v-toolbar-title>Erneuter Login</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="reauth = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row justify="center" style="min-height: 50vh;">
          <v-col cols="12">
            <h3 class="text-center">
              Um die Änderung zu speichern, gib deine alten Login-Daten zur
              Bestätigung ein.
            </h3>
          </v-col>
          <v-col cols="12" v-if="user.provider == 'password'">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row justify="center" class="mt-5">
                <v-col cols="11" md="8" class="py-0">
                  <v-text-field
                    filled
                    label="E-Mail Adresse"
                    :rules="[rules.required, rules.email]"
                    v-model="v.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="11" md="8" class="py-0">
                  <v-text-field
                    filled
                    type="password"
                    label="Passwort"
                    :rules="[rules.required, rules.password]"
                    v-model="v.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="11" class="py-0 text-center my-3">
                  <v-btn
                    :color="template.colors.primary"
                    :style="`color: ${template.colors.inline_primary_text};`"
                    class="my-5"
                    :block="$vuetify.breakpoint.smAndDown"
                    large
                    @click="login()"
                    :loading="loading"
                  >
                    Bestätigen
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col
            cols="auto"
            align-self="center"
            v-if="user.provider != 'password'"
          >
            <v-card
              color="#4285F4"
              dark
              link
              class="my-5 pa-0"
              v-if="user.provider == 'google.com'"
              @click="g_signup()"
            >
              <v-row class="pa-0">
                <v-col cols="auto" class="py-0 mr-0 pr-0">
                  <v-img src="/img/google-login.png" width="50" />
                </v-col>
                <v-col align-self="center">
                  <h3>Mit Google bestätigen</h3>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              color="#3b5998"
              link
              class="my-5 pa-0"
              v-if="user.provider == 'facebook.com'"
              @click="fb_signup()"
            >
              <v-row justify="center" class="pa-0 pr-2">
                <v-col cols="12" align-self="center" class="py-2">
                  <h3>
                    <v-icon class="mx-2" large>mdi-facebook</v-icon>
                    Mit Facebook bestätigen
                  </h3>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              color="#00acee"
              link
              class="my-5 pa-0"
              v-if="user.provider == 'twitter.com'"
              @click="twitter_signup()"
            >
              <v-row justify="center" class="pa-0 pr-2">
                <v-col cols="12" align-self="center" class="py-2">
                  <h3>
                    <v-icon class="mx-2" large>mdi-twitter</v-icon>
                    Mit Twitter bestätigen
                  </h3>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-snackbar
        :color="template.colors.primary"
        :dark="!template.light"
        bottom
        v-model="error"
      >
        {{ errortext }}
      </v-snackbar>
    </v-dialog>
    <v-snackbar
      color="success"
      app
      :dark="!template.light"
      top
      v-model="success"
    >
      {{ art }} ERFOLGREICH GESPEICHERT
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logout from '../../auth/auth'
import router from '../../routes/index'
//import firebase from '../../firebaseConfig'

export default {
  name: 'Mein-Account',
  data() {
    return {
      art: '',
      success: false,
      change_pw: false,
      useredit: {
        email: '',
        passwort: '',
        passwortconfirm: '',
        passwort_old: '',
        displayName: '',
        zuschauerdaten: {
          vorname: '',
          name: '',
          adresse: '',
          plz: '',
          ort: '',
          telefon: '',
        },
      },
      reauth: false,
      error: false,
      errortext: '',
      valid: false,
      pwvalid: false,
      zsvalid: false,
      emailvalid: false,
      deleteconfirm: false,
      deleting: false,
      loading: false,
      v: {
        email: '',
        password: '',
      },
      rules: {
        required: (value) => !!value || 'Bitte gebe diese Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        password: (v) =>
          v.length >= 6
            ? true
            : 'Dein Passwort sollte mindestens 6 Zeichen lang sein',
        passwordconfirm: (v) =>
          v == this.useredit.passwort
            ? true
            : 'Die Passwörter stimmen nicht überein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.user) {
      this.useredit.email = this.user.email
      this.useredit.displayName = this.user.displayName
      if (this.user.zuschauerdaten) {
        this.useredit.zuschauerdaten = this.user.zuschauerdaten
      }
    }
  },
  methods: {
    update_user_zuschauerdaten() {
      if (this.$refs.zsform.validate()) {
        let zs = this.useredit.zuschauerdaten
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.uid)
          .collection('Kontaktdaten')
          .doc(this.user.uid)
          .set({
            vorname: zs.vorname.trim(),
            name: zs.name.trim(),
            adresse: zs.adresse.trim(),
            plz: zs.plz.trim(),
            ort: zs.ort.trim(),
            telefon: zs.telefon.trim(),
          })
          .then(() => {
            this.art = 'KONTAKTDATEN'
            this.success = true
          })
          .catch(function (error) {
            // An error happened.
          })
      }
    },
    update_display_name() {
      var user = firebase.auth().currentUser
      user
        .updateProfile({
          displayName: this.useredit.displayName,
        })
        .then(() => {
          this.user.displayName = this.useredit.displayName
          this.art = 'ANZEIGENAME'
          this.success = true
        })
        .catch(function (error) {
          // An error happened.
        })
    },
    update_user_password() {
      if (this.$refs.pwform.validate()) {
        var user = firebase.auth().currentUser
        user
          .updatePassword(this.useredit.passwort)
          .then(() => {
            this.art = 'PASSWORT'
            this.success = true
          })
          .catch((error) => {
            if (error.code == 'auth/requires-recent-login') {
              this.reauth = true
            }
          })
      }
    },
    update_user_email() {
      if (this.$refs.emailform.validate()) {
        var user = firebase.auth().currentUser
        user
          .updateEmail(this.useredit.email)
          .then(() => {
            this.art = 'E-MAIL'
            this.success = true
          })
          .catch((error) => {
            if (error.code == 'auth/requires-recent-login') {
              this.reauth = true
            }
          })
      }
    },
    deleteUser() {
      this.deleting = true
      var functions = firebase.app().functions('europe-west3')
      var deleteUser = functions.httpsCallable('deleteVereinsnutzer')
      deleteUser({ uid: this.user.uid }).then((result) => {
        if (result.data.success) {
          this.deleting = false
          this.logout()
          //router.push('/')
        } else if (result.data.error) {
          this.deleting = false
          this.error = true
          this.errortext = result.data.error
        }
      })
    },
    g_signup() {
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          if (this.pw_change) {
            this.update_user_password()
            this.reauth = false
            this.pw_change = false
          } else {
            this.update_user_email()
            this.reauth = false
          }
        })
        .catch(function (error) {
          this.error = error
          this.errortext = error.message
        })
    },
    fb_signup() {
      var provider = new firebase.auth.FacebookAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          if (this.pw_change) {
            this.update_user_password()
            this.reauth = false
            this.pw_change = false
          } else {
            this.update_user_email()
            this.reauth = false
          }
        })
        .catch(function (error) {
          this.error = error
          this.errortext = error.message
        })
    },
    twitter_signup() {
      var provider = new firebase.auth.TwitterAuthProvider()
      //provider.addScope('profile_pic')
      firebase.auth().languageCode = 'de'
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          if (this.pw_change) {
            this.update_user_password()
            this.reauth = false
            this.pw_change = false
          } else {
            this.update_user_email()
            this.reauth = false
          }
        })
        .catch((error) => {
          // Handle Errors here.
          this.error = error
          this.errortext = error.message
          console.log(error)
        })
    },
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true
        firebase
          .auth()
          .signInWithEmailAndPassword(this.v.email, this.v.password)
          .then(() => {
            if (this.change_pw) {
              this.update_user_password()
              this.reauth = false
              this.change_pw = false
            } else {
              this.update_user_email()
              this.reauth = false
            }
          })
          .catch((error) => {
            this.loading = false
            this.error = error
            this.errortext = error.message
          })
      }
    },
    logout() {
      logout.logout()
      setTimeout(() => {
        if (this.$route.params.verein) {
          router.push('/' + this.$route.params.verein)
        } else if (this.publicdata) {
          if (this.publicdata.verein) {
            router.push('/' + this.publicdata.verein.alias)
          } else {
            router.push('/')
          }
        } else {
          router.push('/')
        }
      }, 500)
    },
  },
}
</script>
